/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --pixo-font-family-title: 'Kanit', sans-serif;
    --pixo-font-weight-title: 700;
    --pixo-font-letter-spacing-title: 0.05em;
    --pixo-text-transform-title: uppercase;
    --pixo-font-family-subtitle: 'Kanit', sans-serif;
    --pixo-font-weight-subtitle: 400;
    --pixo-font-letter-spacing-subtitle: 0.15em;
    --pixo-text-transform-subtitle: uppercase;
    --pixo-subfont-accent-initial: #888;
    --pixo-subfont-accent-active: #444;
    /* --pixo-background-color: #fff; */ /*Background is more complex and it can be gradient*/
    --pixo-button-color: #000;
    --pixo-set-nav-item-letter-spacing-title: 0.105em;
}

html,
body {
    height: 100%;

    /* disable image select on iOS  */
    -webkit-touch-callout: none;
}

body {
    margin: 0;
    /* font-family: 'IBM Plex Sans Arabic', sans-serif; */
    font-family: 'Kanit', sans-serif;
}

.photo-dialog .mat-mdc-dialog-container .mdc-dialog__surface {
    background-color: transparent;
}

.mat-step-header {
    pointer-events: none !important;
}

.buttons-container {
    @apply flex justify-between sm:justify-start gap-4 sm:gap-x-4 mt-1 flex-row-reverse;
}

.stepper-button {
    @apply px-4 py-2 overflow-visible;
}

.stepper-next-button {
    @apply text-white pixo-button;
}

.stepper-back-button {
    @apply bg-blue-900 text-blue-50;
}


.mat-vertical-content-container {
    margin-left: 0 !important;
}

.mat-vertical-content {
    padding: 0 0 !important;
}

.mat-horizontal-content-container {
    padding: 0 0 !important;
}

.mat-step-header {
    display: none !important;
}

@keyframes fadein-container {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.fadein-animation {
    opacity: 0;
    animation: fadein-container 0.5s linear 2s forwards;
}

.pixo-font-title,
.pixo-font-subtitle {
    font-optical-sizing: auto;
    font-style: normal;
    letter-spacing: 0.1em;
    font-variant-numeric: lining-nums;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.pixo-font-title {
    font-family: var(--pixo-font-family-title
            /*, 'Kanit', sans-serif*/
        );
    font-weight: var(--pixo-font-weight-title, 700);
    letter-spacing: var(--pixo-font-letter-spacing-title, .05em);
    text-transform: var(--pixo-text-transform-title, uppercase);
}

.pixo-font-subtitle {
    font-family: var(--pixo-font-family-subtitle
            /*, 'Kanit', sans-serif*/
        );
    font-weight: var(--pixo-font-weight-subtitle, 400);
    letter-spacing: var(--pixo-font-letter-spacing-subtitle, .15em);
    text-transform: var(--pixo-text-transform-subtitle, uppercase);
}

.pixo-set-nav-item {
    letter-spacing: var(--pixo-set-nav-item-letter-spacing-title, .105em);
}

.pixo-subfont-initial {
    color: var(--pixo-subfont-accent-initial, #888);
}

.pixo-subfont-active {
    color: var(--pixo-subfont-accent-active, #444);
}

.pixo-button {
    background-color: var(--pixo-button-color, #000);
}

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
    display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
